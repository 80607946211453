import { Injectable } from '@angular/core';
import {
  ApiGenericService,
  AbstractAuthService,
  InterceptorConfig,
  ObjectsUtilityService,
  Token,
  UtilityService,
} from 'prg-core-lib';
import { RiskManagementTeamMemberToken } from '../models/risk-management-team.model';

@Injectable({
  providedIn: 'root',
})
export class RiskManagementTeamMemberService {
  private readonly RISK_MANAGEMENT_TEAM_MEMBER_ENDPOINT: string =
    'RiskManagementTeamMember';
  private readonly RISK_MANAGEMENT_TEAM_MEMBER_GET_TOKEN_ENDPOINT: string =
    'GetTeamMemberToken';

  private teamMemberTokens: Map<string, RiskManagementTeamMemberToken> =
    new Map<string, RiskManagementTeamMemberToken>();

  constructor(
    private authService: AbstractAuthService,
    private apiGenericService: ApiGenericService,
    private objectsUtilityService: ObjectsUtilityService,
    private utilityService: UtilityService
  ) {}

  /**
   * this function gets a token for
   * the current user and a given RiskManagementTeam
   * @param riskManagementTeamId
   * @returns
   */
  public getTeamMemberToken(
    riskManagementTeamId: string
  ): RiskManagementTeamMemberToken {
    return this.teamMemberTokens.has(riskManagementTeamId)
      ? this.teamMemberTokens.get(riskManagementTeamId)
      : null;
  }
  /**
   * this function gets a token for
   * the current user and a given RiskManagementTeam
   * @param riskManagementTeamId
   * @returns
   */
  public requestTeamMemberToken(
    riskManagementTeamId: string
  ): Promise<RiskManagementTeamMemberToken> {
    return new Promise<RiskManagementTeamMemberToken>(
      async (resolve, reject) => {
        if (this.teamMemberTokens.has(riskManagementTeamId)) {
          const token = this.teamMemberTokens.get(riskManagementTeamId);
          if (token != null && !this.authService.isJwtTokenExpired(token.exp)) {
            resolve(token);
            return;
          }
        }
        const response = await this.apiGenericService.get(
          this.utilityService.normalizeUrl(
            this.RISK_MANAGEMENT_TEAM_MEMBER_ENDPOINT,
            this.RISK_MANAGEMENT_TEAM_MEMBER_GET_TOKEN_ENDPOINT,
            riskManagementTeamId
          ),
          null,
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: true,
            handleErrors: true,
          })
        );

        if (response == null || response.entity == null) {
          if (this.teamMemberTokens.has(riskManagementTeamId)) {
            this.teamMemberTokens.delete(riskManagementTeamId);
          }
          resolve(null);
          return;
        }

        const decodedToken = this.authService.decodeJwtToken(response.entity);

        const memberToken: RiskManagementTeamMemberToken = <
          RiskManagementTeamMemberToken
        >this.objectsUtilityService.mergeObjectsOnlyCommonProperties(
          decodedToken,
          new RiskManagementTeamMemberToken({})
        );

        if (memberToken != null) {
          if (memberToken.scopes == null || !memberToken.scopes.length) {
            memberToken.scopes = [];
          } else if (!Array.isArray(memberToken.scopes)) {
            memberToken.scopes = [memberToken.scopes];
          }
        }

        memberToken.encodedToken = response.entity;

        this.teamMemberTokens.set(riskManagementTeamId, memberToken);
        resolve(memberToken);
      }
    );
  }

  public isTeamMemberGranted(
    teamId: string,
    permission: string,
    resource: string,
    scopes?: string[]
  ): boolean {
    if (teamId == null) {
      return false;
    }
    if (scopes == null) {
      scopes = this.getTeamMemberToken(teamId)?.scopes;
    }
    if (scopes == null) {
      return false;
    }
    if (permission == null || !permission.length) {
      return true;
    }

    if (resource != null) {
      resource = resource;
      return (
        scopes.find(
          (i) =>
            i === resource + ':' + permission ||
            i === resource + ':*' ||
            i === '*:' + permission ||
            i === '*:*'
        ) !== undefined
      );
    } else {
      return scopes.find((i) => i === permission) !== undefined;
    }
  }
}
