import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthService,
  MainLayoutService,
  PrgConfigHeader,
  PrgConfigSidebarMenu,
} from 'prg-core-lib';
import { PrimeNGConfig } from 'primeng/api';
import {
  LANGUAGE_MENU_HEADER,
  SIDEBAR_MENU,
  USER_MENU_HEADER,
} from './menus-items-layout/menus-structure-layout';

@Component({
  selector: 'app-root',
  /*templateUrl: './app.component.html',*/
  template: `
    <ion-app>
      <router-outlet></router-outlet>
    </ion-app>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private adminRole: string = 'Admin';
  constructor(
    private primeNGConfig: PrimeNGConfig,
    private translateService: TranslateService,
    private router: Router,
    private mainLayoutService: MainLayoutService,
    private authService: AuthService
  ) {
    this.mainLayoutService.setSidebarConfig(
      new PrgConfigSidebarMenu({
        mainMenuItems: SIDEBAR_MENU,
      })
    );

    this.mainLayoutService.setHeaderConfig(
      new PrgConfigHeader({
        menuItemsUser: USER_MENU_HEADER,
        menuItemsLanguage: LANGUAGE_MENU_HEADER,
      })
    );
  }

  ngOnInit(): void {
    // if (!environment.production) {
    //   this.router.events.subscribe((event) => {
    //     if (event instanceof NavigationStart) {
    //       console.log(event.url);
    //     }
    //   });
    // }

    this.setupTranslateService();
    const config = this.router.config;
    config.push({
      path: '**',
      redirectTo: '',
      pathMatch: 'full',
    });
    this.router.resetConfig(config);
  }

  private setupTranslateService() {
    /// Configure translation service
    /// {
    // TODO: Get languages and default language from configuration

    this.translateService.langs = ['pt', 'en'];
    let currentLanguage = this.translateService.langs.includes(
      navigator.language
    )
      ? navigator.language
      : this.translateService.langs[0];

    let userLanguageSaved = JSON.parse(
      localStorage.getItem('corim-language-default')
    );
    if (userLanguageSaved != null) {
      currentLanguage = userLanguageSaved;
    }

    this.setUpLanguage(currentLanguage);
  }
  public setUpLanguage(currentLanguage: string) {
    this.translateService.setDefaultLang(currentLanguage);
    this.translateService.use(currentLanguage);
    this.translateService.onLangChange.subscribe((event: any) => {
      localStorage.removeItem('corim-language-default');
      localStorage.setItem(
        'corim-language-default',
        JSON.stringify(event?.lang)
      );
      this.translateService.get('primeng').subscribe((res) => {
        this.primeNGConfig.setTranslation(res);
      });
    });
  }
}
